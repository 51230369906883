export default [
  {
    component: () => import('@/modules/chat/views/Index.vue'),
    name: 'index',
    path: '/chat',
  },
  {
    component: () => import('@/modules/chat/views/ChatThread.vue'),
    name: 'thread',
    path: '/chat/:chatThreadId',
  },
]
