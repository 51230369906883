/* eslint-disable no-console */
import { register } from 'register-service-worker'

let activeServiceworker = null
let serviceworkerRegistration = null
export function getServiceworker() {
  return activeServiceworker
}
export function getServiceworkerRegistration() {
  return serviceworkerRegistration
}
export default function (store, i18n) {
  /* if (process.env.NODE_ENV !== 'production') {
    return false
  } */
  register('/serviceworker.js', {
    registrationOptions: { scope: '/' },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    ready(registration) {
      serviceworkerRegistration = registration

      activeServiceworker = registration.active
      console.log('App is being served from cache by a service worker.')
      registration.active.postMessage({
        event: 'setConfig',
        data: store.getters['auth/config'],
      })
      window.setTimeout(() => {
        // Reload svgs to update their colors
        Array.from(document.querySelectorAll('img[src*=".svg"]')).forEach((img) => {
          img.src = `${img.src}?${(new Date()).getTime()}`
        })
      }, 500)
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    updated() {
      store.commit('setUpdateAvailable', true)
      if (!window.Capacitor) {
        store.commit('notifications/addNotification', {
          topic: i18n.t('notifications.update_available'),
          title: i18n.t('notifications.reload_to_update'),
          callback() {
            window.location.reload()
          },
          actions: {
            // eslint-disable-next-line func-names
            'App neu laden': function () {
              window.location.reload()
            },
          },
        })
      }
      console.log('New content is available; please refresh.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
  })
  return true
}
